import React from 'react'
import * as Yup from 'yup'

import qs from 'querystring'
import { messages } from '@root/utils/formValidators/messages'
import { validationRules } from '@root/utils/formValidators/rules'
import { PaymentMethods } from '@root/components/PaymentMethodSelector/paymentDetector/AvailablePaymentMethodDetector'
import { Binding } from '@open-api/ump/ewallet-profile'
import { BindingTerms, GetPaymentTermRequest, OperationDetails } from '@root/types/generated/api'
import { formatDate, parseDate } from '@root/utils/formatDate/formatDate'
import { cardTypeValidation, lushValidation, validateEmail } from '@root/utils/formValidators/formValidators'
import { PaymentMethod } from '@components/PaymentMethodSelector/types'
import { FullYupTestContext } from '@root/types/form/types'
import { getMaskByValue } from '@utils/getMaskByValue'
import { ButtonsName } from '@root/components/ActionButton/type'
import { BASIC_RECEIPT } from '@root/components/ActionButton/basicConfig'
import { BindingType, Currency } from '@root/types/bindings'
import repeat from '@icons/repeat.svg'
import star from '@icons/star.svg'
import autopayment from '@icons/autopayments.svg'

const { minCVC } = validationRules

export type Amount = string | number

export const configPaymentWithoutAuth = {
  amountPaymentField: {
    name: 'amount',
    isClearable: true,
    label: 'Сумма платежа',
    size: 'lg',
    allowDecimal: true,
    decimalSymbol: ',',
    hint: undefined,
  },

  cardDataField: {
    cardNumber: {
      name: 'cardNumber',
      label: 'Номер карты',
      isClearable: true,
      placeholder: '0000 0000 0000 0000',
      mask: (rawValue: string) => getMaskByValue(rawValue, false),
      maskBlurred: (rawValue: string) => getMaskByValue(rawValue, true),
    },
    validityPeriodField: {
      name: 'validityPeriod',
      isClearable: true,
      label: 'Срок действия',
      placeholder: 'ММ/ГГ',
      mask: [/\d/, /\d/, '/', /\d/, /\d/],
      className: 'ValidityPeriod',
    },
    CVCField: {
      name: 'CVC',
      isClearable: true,
      label: 'CVC',
      placeholder: 'Введите код',
      mask: [/\d/, /\d/, /\d/],
    },
  },
  paymentSource: {
    name: 'paymentSource',
    placeholder: 'Новая карта',
    isClearable: false,
    filter: (binding: Binding) => binding.bindingType === BindingType.GENERAL_CARD && binding.currency === Currency.RUB,
  },
  fiscalReceiptField: {
    name: 'fiscalReceipt',
    text: 'Мне нужен фискальный чек',
  },
  fiscalEmailField: {
    name: 'fiscalEmail',
    label: 'Электронная почта',
    placeholder: 'mail@example.ru',
    isClearable: true,
  },
  getTermHidden: {
    name: 'getTerm',
  },
  currentBindingHidden: {
    name: 'currentBinding',
  },
  requestGetTermDataHidden: {
    name: 'requestGetTermData',
  },
  allowedPaymentMethodsHidden: {
    name: 'allowedPaymentMethods',
  },
  availablePaymentMethodsHidden: {
    name: 'availablePaymentMethods',
  },
  operatorSelection: 'Данный оператор недоступен для оплаты',
  mtsGroupSelectionError:
    'Не удалось определить сервис. {link href="https://payment.mts.ru/spa/categories/internet_and_tv"}Выберите нужный сервис{/link}',
  offerAgreement: (onOffertClick) => (
    <>
      {`Нажимая на\u00A0кнопку Оплатить, вы\u00A0соглашаетесь`} <br /> {`с\u00A0`}
      <a
        href="https://static.mts.ru/uploadmsk/contents/1655/soglashenie_easy_pay.pdf?_ga=1.125490114.915762629.1465198111"
        onClick={onOffertClick}
        target="_blank"
        rel="noreferrer"
      >
        Договором оферты
      </a>
    </>
  ),
  initialValues: {
    amount: 0 as Amount,
    paymentMethod: PaymentMethods.Card,
    paymentSource: '',
    cardNumber: '',
    validityPeriod: '',
    CVC: '',
    fiscalReceipt: false,
    fiscalEmail: '',
    phoneNumber: '',
    getTerm: {
      inProgress: false,
      hasError: false,
    },
    currentBinding: {} as BindingTerms,
    requestGetTermData: {} as GetPaymentTermRequest,
    allowedPaymentMethods: [] as PaymentMethod[],
    availablePaymentMethods: [] as PaymentMethod[],
  },
  validationSchema: Yup.object().shape({
    paymentSource: Yup.string(),
    cardNumber: Yup.string().when('paymentSource', {
      is: (paymentSource: string) => !paymentSource,
      then: Yup.string()
        .transform((value: string) => value.replace(/\s/g, ''))
        .required('Обязательное поле')
        .min(validationRules.minCardNumber, messages.creditCard.format)
        .max(validationRules.maxCardNumber, messages.creditCard.format)
        .test('cardType', messages.creditCard.format, cardTypeValidation)
        .test('lushCheck', messages.creditCard.format, lushValidation),
    }),
    CVC: Yup.string().when('paymentSource', {
      is: (paymentSource: string) => !paymentSource,
      then: Yup.string().required('Обязательное поле').min(minCVC, 'Обязательное поле'),
    }),
    validityPeriod: Yup.string().when('paymentSource', {
      is: (paymentSource: string) => !paymentSource,
      then: Yup.string()
        .required('Обязательное поле')
        .min(validationRules.minValidityPeriod, 'Обязательное поле')
        .transform((value: string) => formatDate(parseDate(value, '%m/%y')))
        .test(
          'validityCardPeriod',
          messages.creditCard.invalidExpiryDate,
          (value: string, context: FullYupTestContext) =>
            value && formatDate(parseDate(value, '%d.%m.%Y'), 'MM/YY') === context.originalValue
        ),
    }),

    fiscalEmail: Yup.string().when('fiscalReceipt', {
      is: true,
      then: Yup.string()
        .required('Обязательное поле')
        .email(messages.email.required)
        .test('email', messages.email.required, (value: string) => validateEmail(value) === ''),
    }),
  }),
}

export const actionButtonConfig = {
  [ButtonsName.CREATE_TEMPLATE]: {
    name: ButtonsName.CREATE_TEMPLATE,
    description: 'Добавить шаблон',
    icon: star,
    isCustomIcon: true,
    url: '/template/payment',
  },
  [ButtonsName.ENABLE_AUTO_PAYMENTS]: {
    name: ButtonsName.ENABLE_AUTO_PAYMENTS,
    description: 'Создать автоплатеж',
    icon: autopayment,
    isCustomIcon: true,
    url: (operationDetails: OperationDetails) =>
      `/autopayment?serviceId=${operationDetails.service?.serviceId}&${qs.stringify(
        operationDetails.service.serviceParams || {}
      )}`,
    discount: 10,
  },
  [ButtonsName.REPEAT_PAYMENT]: {
    name: ButtonsName.REPEAT_PAYMENT,
    description: 'Повторить платеж',
    icon: repeat,
    isCustomIcon: true,
    url: (operationDetails: OperationDetails) =>
      `/spa/pay/${operationDetails.service?.serviceId}?mdOrder=${operationDetails.mdOrder}`,
  },
  [ButtonsName.DOWNLOAD_RECEIPT]: {
    ...BASIC_RECEIPT,
    name: ButtonsName.DOWNLOAD_RECEIPT,
    description: 'Скачать чек',
  },
}
